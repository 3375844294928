import React, {Children, useEffect, useRef} from "react";
import {CiSquarePlus} from "react-icons/ci";
import {Card} from "@varymadellc/my-weed-club-component-library";
import cx from "classnames";
import ComingSoon from "@/components/ComingSoon/ComingSoon";

export interface MainNavProps {
    LinkAs: any;

    [key: string]: any;
}

export default function MainNav({LinkAs, ...rest}: MainNavProps) {
    const menuRef = useRef<HTMLUListElement>(null);

    useEffect(() => {
        function highlightCurrentPageLinks() {
            document.querySelectorAll('.main-nav-links a[href="' + (new URL(document.URL)).pathname + '"]').forEach(link => link.classList.add('current-page', 'drop-shadow-md'));
        }

        function handleClick(event: any) {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                document.querySelectorAll('.main-nav-links a').forEach(link => link.classList.remove('current-page', 'drop-shadow-md'));
            }

            highlightCurrentPageLinks();
        }

        document.addEventListener('click', handleClick);

        highlightCurrentPageLinks();
    }, []);

    if (typeof LinkAs === 'undefined') {
        return null;
    }

    const Nav = ({children, className, ...rest}: any) => (
        <ul className={cx('ml-0', className)} ref={menuRef} {...rest}>
            {Children.map(children, (navItem: any) => <li
                className="flex whitespace-nowrap cursor-pointer text-xl">{navItem}</li>)}
        </ul>
    );

    return (
        <div className="relative border-b-8 border-secondary drop-shadow-md flex flex-row max-h-[500px]">
            <div className="bg-pattern-3 min-h-full flex-1 overflow-hidden">
                <Card className="mx-4 relative my-8 hidde h-full max-h-[90%] flex flex-col">
                    <LinkAs href="/invite" className="!no-underline text-center bg-tertiary !text-white p-2 text-lg rounded w-full rainbow-hover">Invite a Friend</LinkAs>
                    <hr className="w-full my-4 border-primary/20 rounded"/>
                    <div className="overflow-y-scroll relative mb-4">
                        <ComingSoon>
                            <h2>Some quick features coming soon!</h2>
                            <ul>
                                <li>Trending Diary Entries</li>
                                <li>Top Members</li>
                                <li>User Stats/Info Box</li>
                                <li>Number of diary entries, badges earned, online status.</li>
                                <li>Real-time activity (e.g., &quot;5 new posts in Lounge&quot;).</li>
                            </ul>
                        </ComingSoon>
                    </div>
                    <ComingSoon>
                        <input type="text" placeholder="Search..."
                               className="w-full p-2 border border-secondary rounded"/>
                    </ComingSoon>
                </Card>
            </div>
            <Nav
                className="flex py-10 px-5 animate-top justify-end flex-col relative flex-1 text-right main-nav-links"{...rest}>
                <LinkAs className="py-2 no-underline text-black w-full text-start cursor-pointer hover:text-white"
                        href="/self/profile">Home</LinkAs>
                <LinkAs className="py-2 no-underline text-black w-full text-start cursor-pointer hover:text-white"
                        href="/weed-diary">Weed Diary <CiSquarePlus className="inline"/></LinkAs>
                <LinkAs
                    className="py-2 no-underline text-black w-full text-start cursor-pointer ml-8 text-base hover:text-white"
                    href="/weed-diary/public">- Public Diary Entries</LinkAs>
                <LinkAs
                    className="py-2 no-underline text-black w-full text-start cursor-pointer ml-8 text-base hover:text-white"
                    href="/weed-diary/private">- Private Diary Entries</LinkAs>
                <LinkAs className="py-2 no-underline text-black w-full text-start cursor-pointer hover:text-white"
                        href="/q">Lounge</LinkAs>
                <LinkAs className="py-2 no-underline text-black w-full text-start cursor-pointer hover:text-white"
                        href="/members">Members</LinkAs>
                <LinkAs className="py-2 no-underline text-black w-full text-start cursor-pointer hover:text-white"
                        href="/blog">Blog</LinkAs>
                <LinkAs className="py-2 no-underline text-black w-full text-start cursor-pointer hover:text-white"
                        href="/chat">Chat</LinkAs>
                <hr className="w-full my-4 border-secondary/20 rounded"/>
                <LinkAs
                    className="py-2 no-underline text-black w-full text-start cursor-pointer hover:text-white text-base"
                    href="/logout">Sign Out</LinkAs>
            </Nav>
        </div>
    );
}