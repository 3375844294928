import cx from "classnames";
import {ReactElement} from "react";

export const enum ButtonVariantProps {
    primary= 'primary',
    secondary = 'secondary',
    tertiary = 'tertiary',
    link = 'link',
    dark = 'dark',
    warning = 'warning',
}

export const enum ButtonSizeProps {
    small = 'small',
    base = 'base',
    large = 'large',
}

export interface ButtonProps {
    variant?: ButtonVariantProps;
    size?: ButtonSizeProps;
    disabled?: boolean;
    className?: string;
    type?: 'button' | 'submit' | 'reset';
    children: ReactElement | string;

    [key: string]: any;
}

export default function Button({variant = ButtonVariantProps.primary, size = ButtonSizeProps.base, className, children, type = 'button', disabled = false, ...rest}: ButtonProps) {
    return (
        <button {...rest} disabled={disabled} type={type} className={cx(
            className,
            {'bg-primary': variant.toLowerCase() === ButtonVariantProps.primary},
            {'bg-secondary': variant.toLowerCase() === ButtonVariantProps.secondary},
            {'bg-tertiary': variant.toLowerCase() === ButtonVariantProps.tertiary},
            {'bg-black': variant.toLowerCase() === ButtonVariantProps.dark},
            {'bg-none border-0 text-primary': variant.toLowerCase() === ButtonVariantProps.link},
            {'bg-red-500 border-0 text-black': variant.toLowerCase() === ButtonVariantProps.warning},
            'rounded-sm px-2 py-1 text-black', {'px-1 text-sm lg:text-base': size === ButtonSizeProps.small},
            {'text-xl p-2': size === ButtonSizeProps.large},
            {'!text-gray-400': disabled},
            {'bg-gray-800': disabled && variant.toLowerCase() !== ButtonVariantProps.link},
        )}>{children}</button>
    );
}