import React, {ReactNode} from "react";
import cx from "classnames";

interface ComingSoonProps {
    children: ReactNode;
    className: string;
}

export default function ComingSoon({children, className}: any) {
    return (
        <div className={cx("relative", className)}>
            {children}
            <div className="bg-coming-soon absolute left-0 right-0 top-0 bottom-0 opacity-0 hover:opacity-100 transition-opacity">
                <div className="absolute left-2 right-2 top-2 bottom-2 flex items-center">
                    <p className="font-bold uppercase">Coming Soon!</p>
                </div>
            </div>
        </div>
    );
};