import cx from "classnames";
import React, {ReactNode, useEffect, useRef} from "react";
import {useRouter} from "next/router";
import Link from "next/link";

export interface HeaderProps {
    menu?: ReactNode;
    className?: string;
    hideHeaderLogo?: boolean;
    theme?: string;
}

export default function Header({menu, className, hideHeaderLogo = true, theme = 'green'}: HeaderProps) {
    const router = useRouter();

    const [navWidth, setNavWidth] = React.useState(500);

    const headerRef = React.useRef<HTMLDivElement>(null);

    const menuRef = React.useRef<HTMLDivElement>(null);

    const [menuOpen, setMenuOpen] = React.useState(false);

    const toggleMenu = () => setMenuOpen(!menuOpen);

    useEffect(() => {
        if (!menuRef.current) return;
        menuRef.current.onclick = toggleMenu;
        menuRef.current.style.display = menuOpen ? 'block' : 'none';
    }, [menuOpen, menuRef.current]);

    useEffect(() => {
        if (!headerRef.current) return;

        const navWidth = headerRef.current.clientWidth;

        setNavWidth(navWidth);
    }, [])

    useEffect(() => {
        document.addEventListener('keyup', (event) => {
            if (event.key === 'Escape') {
                setMenuOpen(false);
            }
        });
    }, []);

    return (
        <div ref={headerRef} className={cx("w-full max-w-full min-h-80px]", className)}>
            <div className="w-full stuck" style={{maxWidth: navWidth}}>
                <div ref={menuRef} className={cx("w-full absolute z-0 bg-primary text-black flex flex-col hidden")}>
                    <div className="relative mt-[60px] text-black">
                        {menu}
                    </div>
                </div>
                <div className="flex flex-row w-full relative z-1 gap-0 m-0 h-[60px]">
                    <div
                        onClick={() => router.push('/')}
                        style={{backgroundImage: `url(/themes/${theme || 'green'}/bg@2x.png)`}}
                        className={`flex w-full items-center bg-contain bg-repeat-x relative min-w-[120px] sm:min-w-[160px] max-w-[240px] overflow-x-hidden`}>
                        {
                            !hideHeaderLogo && (
                                <Link href="/" className="relative z-[10] w-full h-full">
                                    <img src="/imgs/full-logo.png" alt="logo"
                                         className="absolute left-0 top-[50%] -translate-y-[50%] w-[200px] p-2 hidden sm:block md:block lg:block xl:block"/>
                                    <img src="/imgs/icon.png" alt="logo"
                                         className="absolute left-0 max-h-full w-auto p-2 block sm:hidden md:hidden lg:hidden xl:hidden"/>
                                </Link>
                            )
                        }
                    </div>
                    <div
                        className="flex w-full items-center bg-contain bg-repeat-x relative"
                        style={{backgroundImage: `url(/themes/${theme || 'green'}/bg@2x.png)`}}>
                        &nbsp;
                    </div>
                    <img className="m-0 w-[60px]"
                         src={`/themes/${theme || 'green'}/left@2x.png`} alt="middle"/>
                    <div
                        style={{backgroundImage: `url(/themes/${theme || 'green'}/middle@2x.png)`}}
                        className={`min-w-[100px] bg-repeat-x max-h-full relative bg-contain w-full max-w-[200px]`}>
                        <div onClick={toggleMenu}
                             className="flex absolute top-0 left-0 right-0 bottom-0 justify-center items-center cursor-pointer">
                            <span className={cx("text-black text-xl sm:ml-4 mt-4 whitespace-nowrap cursor-pointer", {"p-2 border-b border-secondary/30": menuOpen})}>{!menuOpen ? 'Menu' : 'Close Menu'}</span>
                        </div>
                    </div>
                    <img src={`/themes/${theme || 'green'}/right@2x.png`} className="w-full max-w-[66px]"/>
                </div>
            </div>
        </div>
    );
}