import React, {useState} from "react";
import {Button} from "../components/Button";
import {useAuth} from "@/providers/AuthProvider";
import {useRouter} from "next/navigation";
import Link from "next/link";
import Page from "@/layout/Page";
import SignInForm from "@/components/scene/SignInForm";
import {ButtonSizeProps} from "@/components/Button/Button";
import Input from "@/components/Input/Input";

export default function Index() {
    const {isValidToken} = useAuth();

    const navigate = useRouter();

    if (isValidToken()) {
        navigate.push('/self/profile');
        return;
    }

    function QueueAccessCodeRedirect() {
        const [code, setCode] = useState('');

        return (
            <form className="flex-row m-1" onSubmit={(event) => {
                event.preventDefault();

                const path = `${window.location.protocol}//${window.location.host}/q/code/${code}`;

                navigate.push(path);
            }}>
                <Input onChange={({target}) => setCode(target.value)} type="text" placeholder="code" className="flex-[3]"/>
                <Button className="rounded-l-none flex-1" type="submit">Go!</Button>
            </form>
        );
    }

    return (
        <Page>
            <div className="relative overflow-hidden w-full flex flex-col justify-center">
                <div className="w-12/12 mx-auto mt-[40px] relative z-0 text-center">
                    <h2 className="capitalize">An online social cannabis club</h2>
                    <br/>
                    <p className="text-sm">🌿 Welcome to our vibrant online cannabis community! 🌿</p>
                </div>
                <div className="w-10/12 mx-auto mt-16 p-8 rounded bg-secondary">
                    <h2>Welcome back, Sign-in Below</h2>
                    <SignInForm/>
                </div>
                <div className="w-10/12 mx-auto my-8 p-8 rounded bg-secondary">
                    <Button size={'small' as ButtonSizeProps.small} className="px-10 py-5 mb-8 w-full rounded"
                            onClick={() => {
                                gtag('event', 'firm_signup')

                                navigate.push('/signup#signup')
                            }}>Sign Up
                    </Button>
                    <p>Join MyWeed.Club for FREE and connect with a vibrant cannabis community! Signing up unlocks exclusive features like member profiles, a weed diary, cannabis/weed event information, and more. Whether you&apos;re here to learn, share, or just vibe, we&apos;ve got something for everyone. Don’t miss out — sign up today and become part of the community!</p>
                </div>
                <div className="w-10/12 flex flex-col justify-center items-center mx-auto gap-20">
                    <div className="flex-1 flex flex-col">
                        <p>Are you ready to embark on a journey where like-minded individuals gather to share, connect,
                            and celebrate the wonderful world of cannabis? Look no further! Our platform is your digital
                            oasis, a place where enthusiasts, connoisseurs, and those curious about cannabis come
                            together in harmony.</p>
                        <br/>
                        <p>👋 Join us and discover a space that&apos;s more than just a website; it&apos;s a
                            cannabis-lover&apos;s home. 🚀 Here, you&apos;ll find community, informative discussions, and
                            an virtual lounge—all in a safe and responsible environment. 🌱 Don&apos;t miss out on the
                            fun! Sign up today, and let&apos;s explore the limitless possibilities of cannabis culture
                            together! 🎉
                        </p>
                        <br/>
                        <p className="bg-black/80 rounded p-1 text-center">
                            <Link className="underline pointer text-2xl" href="/signup/brands">Are you a brand? Read here.</Link>
                        </p>
                    </div>
                </div>
                <div className="bg-black/80 w-8/12 mx-auto m-10 p-10 rounded text-center">
                    <small>Absolutely no sales/buying on or associated with MyWeed.Club.</small>
                </div>
            </div>
        </Page>
    );
}

export async function getServerSideProps() {
    return {
        props: {
            showFooter: false,
        }
    }
}